import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';

const Wrapper = styled.div`
  width: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: #eee;
  z-index: 100;
  box-shadow: 0 0 5px red;
`;
const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Registration = ({ history }) => {
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const [passwordComfirmed, setpasswordComfirmed] = useState();

  const switchToAuth = () => {
    history.push('/signin');
  };

  return (
    <>
      <Backdrop></Backdrop>
      <Wrapper>
        <Input
          inputType="text"
          value={username}
          changed={e => setusername(e.target.value)}
          placeholder="имя пользователя"
          center
        />
        <Input
          inputType="password"
          value={password}
          changed={e => setpassword(e.target.value)}
          placeholder="пароль"
          center
        />
        <Input
          inputType="password"
          value={passwordComfirmed}
          changed={e => setpasswordComfirmed(e.target.value)}
          placeholder="подтверждение пароля"
          center
        />
        <Button btnType="primary" center>
          Регистрация
        </Button>
        <Button btnType="secondary" center clicked={switchToAuth}>
          Войти
        </Button>
      </Wrapper>
    </>
  );
};

Registration.propTypes = {
  history: PropTypes.array.isRequired,
};

const mapStateToProps = store => {
  return {};
};

const mapDispatchToProps = store => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Registration));
