import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const colors = {
  primary: ['#00ff00', '#555'],
  secondary: ['#229922', 'transparent'],
  danger: ['#fff', 'red'],
  close: ['red', 'transparent'],
  edit: ['#fff', 'green'],
};

const Wrapper = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: ${props => (props.noMargins ? '' : '10px')}
    ${props => (props.center ? 'auto' : '')};
  padding: 5px 10px;
  width: ${props => props.size + 'px'};
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  color: ${props => colors[props.btnType][0]};
  background: ${props => colors[props.btnType][1]};
  &:hover {
    transform: ${props =>
      props.btnType === 'primary' || props.btnType === 'secondary'
        ? 'translateY(-1px)'
        : ''};
  }
  &:active {
    transform: translateY(0);
  }
`;

export const Button = ({
  btnType,
  children,
  clicked = () => {},
  center,
  noMargins,
  size = 150,
}) => {
  return (
    <Wrapper
      btnType={btnType}
      onClick={clicked}
      center={center}
      noMargins={noMargins}
      size={size}
    >
      {children}
    </Wrapper>
  );
};

Button.propTypes = {
  btnType: PropTypes.oneOf([
    'primary',
    'secondary',
    'danger',
    'close',
    'edit',
  ]).isRequired,
  children: PropTypes.any.isRequired,
  clicked: PropTypes.func,
  center: PropTypes.bool,
  noMargins: PropTypes.bool,
  size: PropTypes.number,
};

Button.defaultProps = {
  clicked: () => {},
  center: false,
  noMargins: false,
  size: 150,
};

export default Button;
