import * as Constants from '../constants';

const initialState = {
  username: '',
  token: localStorage.getItem('unload_token'),
  IMEI: '',
  isLoading: false,
  error: '',
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.LOGIN_PENDING:
      localStorage.removeItem('unload_token');
      state = {
        ...state,
        username: '',
        token: null,
        error: '',
        IMEI: '',
        isLoading: true,
      };
      break;
    case Constants.LOGIN_FULFILLED:
      localStorage.setItem('unload_token', action.payload.data.token);
      state = {
        ...state,
        username: action.payload.data.username,
        token: action.payload.data.token,
        IMEI: '',
        isLoading: false,
      };
      break;
    case Constants.LOGIN_REJECTED:
      state = {
        ...state,
        error: action.payload.message,
        isLoading: false,
      };
      break;
    case Constants.LOGOUT:
      localStorage.removeItem('unload_token');
      state = {
        ...state,
        username: '',
        token: null,
        IMEI: '',
        isLoading: false,
      };
      break;
    case Constants.REGISTER_PENDING:
      localStorage.removeItem('unload_token');
      state = {
        ...state,
        username: '',
        token: null,
        error: '',
        IMEI: '',
        isLoading: false,
      };
      break;
    case Constants.REGISTER_FULFILLED:
      localStorage.setItem('unload_token', action.payload.data.token);
      state = {
        ...state,
        username: action.payload.data.username,
        token: action.payload.data.token,
        IMEI: '',
        isLoading: false,
      };
      break;
    case Constants.REGISTER_REJECTED:
      state = {
        ...state,
        error: action.payload.message,
        isLoading: false,
      };
      break;

    default:
      break;
  }

  return state;
};
