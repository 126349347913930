import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLoadList,
  openDocument,
  closeDocument,
  updateDocument,
} from '../../store/actions/unloadActions';

import Modal from '../../components/UI/Modal';
import UnloadDocument from '../../components/UnloadDocument';

const Wrapper = styled.div`
  margin: 5px;
`;
const Element = styled.div`
  padding: 10px;
  background-color: #eee;
  z-index: 100;
  margin: 5px;
  border: 1px solid green;
  border-radius: 5px;
  cursor: ${props => (props.header ? '' : 'pointer')};
  text-align: ${props => (props.header ? 'center' : '')};
  display: grid;
  gap: 10px;
  grid-template-columns: 200px 1fr 400px;
  &:hover {
    box-shadow: ${props => (props.header ? '' : '0 0 5px green')};
  }
  @media (max-width: 992px) {
    grid-template-columns: 100px 1fr 380px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 100px 1fr 190px;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;
const ElementNumDate = styled.div`
  text-align: center;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }
`;
const ElementPartner = styled.div`
  font-weight: bold;
  @media (max-width: 576px) {
    text-align: center;
  }
`;
const ElementTransport = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
  }
`;

export const UnloadList = () => {
  const unloadList = useSelector(store => {
    return store.unload.unloadList;
  });
  const currentDocument = useSelector(store => {
    return store.unload.currentDocument;
  });
  const activeTab = useSelector(store => store.menu.activeTab);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoadList(activeTab));
    return () => {};
  }, [activeTab]);

  const unloadListView = unloadList.map(el => {
    return (
      <Element
        key={el.number}
        onClick={() => dispatch(openDocument(el.transportNumber))}
      >
        <ElementNumDate>
          <div>{el.number}</div>
          <div>{el.date}</div>
        </ElementNumDate>
        <ElementPartner>{el.partner}</ElementPartner>
        <ElementTransport>
          <div>{el.transportNumber}</div>
          <div>{el.transportType}</div>
        </ElementTransport>
      </Element>
    );
  });

  unloadListView.unshift(
    <Element key="elementsHeader" header>
      <ElementNumDate>
        <div>Номер</div>
        <div>Дата</div>
      </ElementNumDate>
      <ElementPartner>Партнер</ElementPartner>
      <ElementTransport>
        <div>№ транспорта</div>
        <div>Вид транспорта</div>
      </ElementTransport>
    </Element>
  );

  return (
    <>
      {currentDocument ? (
        <Modal>
          <UnloadDocument
            closeDoc={() => dispatch(closeDocument())}
            updateDoc={(photoes, defects, confirmity) =>
              dispatch(
                updateDocument(
                  activeTab,
                  currentDocument.docUID,
                  currentDocument.forwardingReciepts,
                  photoes,
                  defects,
                  confirmity
                )
              )
            }
          />
        </Modal>
      ) : null}
      <Wrapper>{unloadListView}</Wrapper>
    </>
  );
};

export default UnloadList;
