import {
  GET_LOAD_LIST,
  OPEN_LOAD_DOCUMENT,
  CLOSE_LOAD_DOCUMENT,
  UPDATE_LOAD_DOCUMENT,
  CHOOSE_RECIEPT,
  CLOSE_RECIEPT,
  SAVE_PHOTO_RECIEPT,
} from '../constants';
import instance from '../axios-docs';

export const getLoadList = type => {
  return {
    type: GET_LOAD_LIST,
    payload: instance.get('/api/unload', { params: { type } }),
  };
};

export const openDocument = transportNumber => {
  return {
    type: OPEN_LOAD_DOCUMENT,
    payload: { transportNumber },
  };
};

export const closeDocument = () => {
  return {
    type: CLOSE_LOAD_DOCUMENT,
  };
};

export const updateDocument = (
  type,
  docUID,
  reciepts,
  recieptsPhoto,
  recieptsDefects,
  recieptsConfirmity
) => {
  return {
    type: UPDATE_LOAD_DOCUMENT,
    payload: instance.post('/api/unload', {
      type,
      docUID,
      reciepts,
      recieptsPhoto,
      recieptsDefects,
      recieptsConfirmity,
    }),
  };
};

export const chooseReciept = UID => {
  return {
    type: CHOOSE_RECIEPT,
    payload: { UID },
  };
};

export const closeReciept = UID => {
  return {
    type: CLOSE_RECIEPT,
  };
};

export const savePhotoReciept = () => {
  return {
    type: SAVE_PHOTO_RECIEPT,
  };
};
