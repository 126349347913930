import React from 'react';
import PropTypes from 'prop-types';

import {
  Switch,
  Route,
  withRouter,
  Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Registration from '../Registration';
import Auth from '../Auth';
import Main from '../Main';

export const Layout = () => {
  const token = useSelector(store => store.auth.token);

  if (token === null) {
    return (
      <Switch>
        <Route path="/signin" component={Auth} />
        <Route path="/registration" component={Registration} />
        <Route path="/">
          <Redirect to="/signin" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

Layout.propTypes = {
  token: PropTypes.string,
};

Layout.defaultTypes = {
  token: null,
};

export default withRouter(Layout);
