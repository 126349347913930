import axios from 'axios';

axios.defaults.withCredentials = true;

const instance = axios.create({
  // baseURL: 'http://192.168.1.60:8888',
  // baseURL: 'http://localhost:8888',
  baseURL: 'https://unload.na4u.ru',
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${store.auth.token}`,
    Authorization: `Bearer ${localStorage.getItem('unload_token')}`,
  },
  // withCredentials: true,
});

// instance.interceptors.request.use(
//   config => {
//     const token = localStorage.getItem('token');
//     if (token) config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

export default instance;
