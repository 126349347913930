import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { login } from '../../store/actions/authActions';

import Input from '../../components/UI/Input';
import Button from '../../components/UI/Button';

const Wrapper = styled.div`
  width: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: #eee;
  z-index: 100;
  box-shadow: 0 0 5px red;
`;
const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Auth = ({ history }) => {
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const err = useSelector(store => store.auth.error);
  const dispatch = useDispatch();

  const switchToRegistration = () => {
    history.push('/registration');
  };

  return (
    <>
      <Backdrop></Backdrop>
      <Wrapper>
        <Input
          inputType="text"
          value={username}
          changed={e => setusername(e.target.value)}
          placeholder="имя пользователя"
          center
        />
        <Input
          inputType="password"
          value={password}
          changed={e => setpassword(e.target.value)}
          placeholder="пароль"
          center
        />
        <Button
          btnType="primary"
          center
          clicked={() => dispatch(login(username, password))}
        >
          Войти
        </Button>
        <Button
          btnType="secondary"
          center
          size={250}
          clicked={switchToRegistration}
        >
          Зарегистрироваться
        </Button>
        {err ? <div>{err}</div> : null}
      </Wrapper>
    </>
  );
};

Auth.propTypes = {
  history: PropTypes.array.isRequired,
};

export default withRouter(Auth);
