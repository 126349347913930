import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Webcam from 'react-webcam';

import Button from '../../components/UI/Button';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #aaa;
  display: grid;
  grid-template-rows: 40px 1fr 60px;
`;
const Photoes = styled.div`
  width: 300px;
  height: 405px;
  overflow-y: auto;
  scrollbar-color: #102a43 #829ab1;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;
const Photo = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 50px;
  margin-top: 10px;
  height: 200px;
`;
const PhotoImg = styled.img`
  width: 250px;
  height: 185px;
`;
const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr) 50px;
  gap: 5px;
  text-align: center;
`;
const MainButton = styled.div`
  /* margin-bottom: 15px; */
`;
const InputButton = styled.input`
  display: none;
`;
const WebcamView = styled.div`
  border: 1px solid black;
`;
const Confirmity = styled.div`
  margin: 5px auto;
  padding: 5px;
  cursor: pointer;
  display: flex;
`;
const ConfirmityCB = styled.input`
  margin-right: 5px;
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
`;
const ConfirmityLabel = styled.label`
  cursor: pointer;
  display: block;
`;
const DataDefects = styled.div`
  margin: 5px;
  padding-left: 10px;
  padding-top: 5px;
  border-radius: 3px;
  cursor: pointer;
  background-color: ${props => (props.isMarked ? 'red' : '#fff')};
`;

export const RecieptDocument = ({
  photoes,
  allDefects,
  recieptsDefects,
  confirmity,
  takePhoto,
  deletePhoto,
  setDefects,
  setData,
  closeDoc,
}) => {
  const webcamRef = useRef(null);
  const [tab, settab] = useState('face');

  const getFile = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onload = () => {
      takePhoto(reader.result);
    };

    reader.readAsDataURL(file);
  };

  let mainView = null;
  switch (tab) {
    case 'face':
      mainView = (
        <>
          <WebcamView>
            <Webcam
              audio={false}
              height={450}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={320}
              screenshotQuality={1}
              videoConstraints={{
                width: 1015,
                height: 720,
                // facingMode: 'user',
                facingMode: { exact: 'environment' },
              }}
            />
          </WebcamView>
          <MainButton>
            <Button
              btnType="primary"
              size={250}
              center
              clicked={() =>
                takePhoto(webcamRef.current.getScreenshot())
              }
            >
              Сделать фото
            </Button>
          </MainButton>
        </>
      );
      break;
    case 'back':
      const photoesView = photoes.map((el, ind) => {
        return (
          <Photo key={el.photo}>
            <PhotoImg src={el.photo} />
            <Button
              btnType="close"
              size={30}
              clicked={() => deletePhoto(el.timestamp)}
            >
              X
            </Button>
          </Photo>
        );
      });

      mainView = (
        <>
          <Photoes>{photoesView}</Photoes>
          <MainButton>
            <label htmlFor="imageFile">
              <Button
                btnType="primary"
                size={250}
                center
                clicked={() => {}}
              >
                Добавить фото
              </Button>
            </label>
            <InputButton
              type="file"
              id="imageFile"
              onChange={getFile}
            />
          </MainButton>
        </>
      );
      break;
    case 'data':
      const defects = allDefects.map(el => {
        return (
          <DataDefects
            key={el.uid}
            onClick={() => setDefects(el.uid)}
            isMarked={
              recieptsDefects[0]
                ? recieptsDefects[0].defects.filter(
                    elR => elR.uid === el.uid
                  ).length > 0
                : false
            }
          >
            {el.text}
          </DataDefects>
        );
      });

      mainView = (
        <div>
          <Confirmity>
            <ConfirmityCB
              type="checkbox"
              id="confirmity"
              checked={confirmity[0] ? confirmity[0].value : false}
              onChange={el => setData(el.currentTarget.checked)}
            />
            <ConfirmityLabel htmlFor="confirmity">
              Соответствие схеме
            </ConfirmityLabel>
          </Confirmity>
          <div>{defects}</div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <Wrapper>
      <Buttons>
        <Button
          btnType="edit"
          size={140}
          noMargins
          clicked={() => settab(tab === 'back' ? 'face' : 'back')}
        >
          {tab === 'back' ? 'Камера' : `Фото (${photoes.length})`}
        </Button>
        <Button
          btnType="edit"
          size={120}
          noMargins
          clicked={() => settab('data')}
        >
          Данные
        </Button>
        <Button
          btnType="close"
          size={50}
          noMargins
          clicked={closeDoc}
        >
          X
        </Button>
      </Buttons>
      {mainView}
    </Wrapper>
  );
};

RecieptDocument.propTypes = {
  photoes: PropTypes.array.isRequired,
  allDefects: PropTypes.array.isRequired,
  recieptsDefects: PropTypes.array.isRequired,
  confirmity: PropTypes.array.isRequired,
  takePhoto: PropTypes.func.isRequired,
  deletePhoto: PropTypes.func.isRequired,
  setDefects: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  closeDoc: PropTypes.func.isRequired,
};

export default RecieptDocument;
