import * as Constants from '../constants';

const initialState = {
  errorLoading: '',
  isLoading: false,
  defects: [
    {
      uid: 'fr32311',
      text: 'причина 1',
    },
    {
      uid: 'fr32312',
      text: 'причина 2',
    },
    {
      uid: 'fr32313',
      text: 'причина 3',
    },
    {
      uid: 'fr32314',
      text: 'причина 4',
    },
    {
      uid: 'fr32315',
      text: 'причина 5',
    },
  ],
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_COMMON_DATA_PENDING:
      state = {
        ...state,
        errorLoading: '',
        isLoading: true,
        defects: [],
      };
      break;
    case Constants.GET_COMMON_DATA_FULFILLED:
      state = {
        ...state,
        isLoading: false,
        defects: action.payload.data.data,
      };
      break;
    case Constants.GET_COMMON_DATA_REJECTED:
      state = {
        ...state,
        errorLoading: 'error loading common data',
        isLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
};
