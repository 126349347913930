import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { changeTab } from '../../store/actions/menuActions';
import { logout } from '../../store/actions/authActions';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 50px;
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, 1fr);
  z-index: 500;
  background-color: #fff;
  margin: auto;
  justify-items: center;
  align-items: center;
  @media (max-width: 320px) {
    grid-template-columns: repeat(4, 80px);
    gap: 0;
  }
`;
const MenuButton = styled.div`
  background-color: ${props => (props.active ? 'green' : '#fff')};
  color: ${props => (props.active ? 'white' : 'green')};
  border: 1px solid green;
  border-radius: 5px;
  min-width: 80px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
`;
const ExitButton = styled.div`
  background-color: #888;
  color: white;
  border: 1px solid red;
  border-radius: 5px;
  min-width: 65px;
  text-align: center;
  padding: 5px;
  font-size: 16px;
  cursor: pointer;
`;

export const MainMenu = () => {
  const activeTab = useSelector(store => store.menu.activeTab);
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <MenuButton
        active={activeTab === 'load'}
        onClick={() => dispatch(changeTab('load'))}
      >
        Погрузки
      </MenuButton>
      <MenuButton
        active={activeTab === 'unload'}
        onClick={() => dispatch(changeTab('unload'))}
      >
        Разгрузки
      </MenuButton>
      <MenuButton
        active={activeTab === 'reciept'}
        onClick={() => dispatch(changeTab('reciept'))}
      >
        Расписки
      </MenuButton>
      <ExitButton
        active={activeTab === 'exit'}
        onClick={() => dispatch(logout())}
      >
        Выход
      </ExitButton>
    </Wrapper>
  );
};

export default MainMenu;
