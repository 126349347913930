import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 320px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 10px; */
  background-color: #eee;
  z-index: ${props => props.level * 1000};
  box-shadow: 0 0 5px green;
`;
const Backdrop = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Modal = ({ children, level }) => {
  return (
    <>
      <Backdrop></Backdrop>
      <Wrapper level={level}>{children}</Wrapper>
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  level: PropTypes.number,
};

Modal.defaultProps = {
  level: 1,
};

export default Modal;
