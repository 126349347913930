import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.input`
  font-size: 16px;
  margin: 10px ${props => (props.center ? 'auto' : '')};
  display: ${props => (props.center ? 'block' : '')};
  padding: 5px 10px;
  width: ${props => props.size + 'px'};
  border-radius: 5px;
  border: 1px solid green;
  &:focus {
    box-shadow: 0 0 2px green;
  }
`;

export const Input = ({
  inputType,
  value,
  changed,
  placeholder,
  size = 250,
  center,
}) => {
  return (
    <Wrapper
      type={inputType}
      size={size}
      center={center}
      placeholder={placeholder}
      onChange={changed}
      value={value}
    />
  );
};

Input.propTypes = {
  inputType: PropTypes.oneOf(['text', 'number', 'password']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  changed: PropTypes.func,
  size: PropTypes.number,
  placeholder: PropTypes.string,
  center: PropTypes.bool,
};

Input.defaultProps = {
  inputType: 'text',
  value: '',
  changed: () => {},
  size: 250,
  placeholder: '',
  center: false,
};

export default Input;
