import { REGISTER, LOGIN, LOGOUT } from '../constants';
import instance from '../axios-docs';

export const registration = (
  username,
  password,
  passwordConfirm,
  invite
) => {
  return {
    type: REGISTER,
    payload: { username, password, passwordConfirm, invite },
  };
};

export const login = (username, password) => {
  return {
    type: LOGIN,
    payload: instance.post('/api/auth/login', { username, password }),
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
