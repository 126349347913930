export const LOGIN = 'LOGIN';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_REJECTED = 'LOGIN_REJECTED';

export const REGISTER = 'REGISTER';
export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_FULFILLED = 'REGISTER_FULFILLED';
export const REGISTER_REJECTED = 'REGISTER_REJECTED';

export const LOGOUT = 'LOGOUT';

export const GET_LOAD_LIST = 'GET_LOAD_LIST';
export const GET_LOAD_LIST_PENDING = 'GET_LOAD_LIST_PENDING';
export const GET_LOAD_LIST_FULFILLED = 'GET_LOAD_LIST_FULFILLED';
export const GET_LOAD_LIST_REJECTED = 'GET_LOAD_LIST_REJECTED';

export const CHANGE_TAB = 'CHANGE_TAB';

export const OPEN_LOAD_DOCUMENT = 'OPEN_LOAD_DOCUMENT';
export const CLOSE_LOAD_DOCUMENT = 'CLOSE_LOAD_DOCUMENT';

export const UPDATE_LOAD_DOCUMENT = 'UPDATE_LOAD_DOCUMENT';
export const UPDATE_LOAD_DOCUMENT_PENDING =
  'UPDATE_LOAD_DOCUMENT_PENDING';
export const UPDATE_LOAD_DOCUMENT_FULFILLED =
  'UPDATE_LOAD_DOCUMENT_FULFILLED';
export const UPDATE_LOAD_DOCUMENT_REJECTED =
  'UPDATE_LOAD_DOCUMENT_REJECTED';

export const CHOOSE_RECIEPT = 'CHOOSE_RECIEPT';
export const CLOSE_RECIEPT = 'CLOSE_RECIEPT';

export const SAVE_PHOTO_RECIEPT = 'SAVE_PHOTO_RECIEPT';
export const SAVE_PHOTO_RECIEPT_PENDING =
  'SAVE_PHOTO_RECIEPT_PENDING';
export const SAVE_PHOTO_RECIEPT_FULFILLED =
  'SAVE_PHOTO_RECIEPT_FULFILLED';
export const SAVE_PHOTO_RECIEPT_REJECTED =
  'SAVE_PHOTO_RECIEPT_REJECTED';

export const GET_COMMON_DATA = 'GET_COMMON_DATA';
export const GET_COMMON_DATA_PENDING = 'GET_COMMON_DATA_PENDING';
export const GET_COMMON_DATA_FULFILLED = 'GET_COMMON_DATA_FULFILLED';
export const GET_COMMON_DATA_REJECTED = 'GET_COMMON_DATA_REJECTED';
