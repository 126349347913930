import * as Constants from '../constants';

const initialState = {
  unloadList: [],
  errorLoading: '',
  isLoading: false,
  currentDocument: null,
  isUpdating: false,
  errorUpdating: '',
  currentReciept: null,
  isSendingPhoto: false,
  defects: [
    {
      uid: 'fr32311',
      text: 'причина 1',
    },
    {
      uid: 'fr32312',
      text: 'причина 2',
    },
    {
      uid: 'fr32313',
      text: 'причина 3',
    },
    {
      uid: 'fr32314',
      text: 'причина 4',
    },
    {
      uid: 'fr32315',
      text: 'причина 5',
    },
  ],
};

export const unloadReducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.GET_LOAD_LIST_PENDING:
      state = {
        ...state,
        unloadList: [],
        // unloadList: [
        //   {
        //     number: 'fgsdfg',
        //     date: '16.04.2020',
        //     transportNumber: 'qwerty',
        //     transportType: 'closet',
        //     partner: 'test',
        //     docUID: '123456789',
        //     forwardingReciepts: [
        //       {
        //         UID: '0987654',
        //         number: 'sdfghj',
        //         date: '16.04.2020',
        //         places: 3,
        //         weight: 1400,
        //         space: 0.5,
        //         comment: '',
        //       },
        //       {
        //         UID: '456543',
        //         number: 'hjkghk',
        //         date: '16.04.2020',
        //         places: 1,
        //         weight: 500,
        //         space: 0.1,
        //         comment: '',
        //       },
        //     ],
        //   },
        // ],
        errorLoading: '',
        isLoading: true,
      };
      break;
    case Constants.GET_LOAD_LIST_FULFILLED:
      state = {
        ...state,
        unloadList: [...action.payload.data.data],
        isLoading: false,
      };
      break;
    case Constants.GET_LOAD_LIST_REJECTED:
      state = {
        ...state,
        errorLoading: action.payload.message,
        isLoading: false,
      };
      break;
    case Constants.OPEN_LOAD_DOCUMENT:
      const docs = state.unloadList.filter(
        el => el.transportNumber === action.payload.transportNumber
      );
      let doc = null;
      if (docs.length > 0) {
        doc = docs[0];
      }
      state = {
        ...state,
        currentDocument: doc,
      };
      break;
    case Constants.CLOSE_LOAD_DOCUMENT:
      state = {
        ...state,
        currentDocument: null,
      };
      break;
    case Constants.UPDATE_LOAD_DOCUMENT_PENDING:
      state = {
        ...state,
        isUpdating: true,
        errorUpdating: '',
      };
      break;
    case Constants.UPDATE_LOAD_DOCUMENT_FULFILLED:
      state = {
        ...state,
        currentDocument: null,
        isUpdating: false,
      };
      break;
    case Constants.UPDATE_LOAD_DOCUMENT_REJECTED:
      state = {
        ...state,
        isUpdating: false,
        errorUpdating: 'что-то пошло не так!',
      };
      break;
    case Constants.CHOOSE_RECIEPT:
      const docsReciept = state.currentDocument.forwardingReciepts.filter(
        el => el.UID === action.payload.UID
      );
      let docReciept = null;
      if (docsReciept.length > 0) {
        docReciept = docsReciept[0];
      }
      state = {
        ...state,
        currentReciept: docReciept,
      };
      break;
    case Constants.CLOSE_RECIEPT:
      state = {
        ...state,
        currentReciept: null,
      };
      break;
    default:
      break;
  }

  return state;
};
