import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/UI/Button';
import Modal from '../../components/UI/Modal';
import RecieptDocument from '../../components/RecieptDocument';
import {
  chooseReciept,
  closeReciept,
} from '../../store/actions/unloadActions';

const Wrapper = styled.div``;
const HeaderNumber = styled.div`
  display: grid;
  grid-template-columns: 180px 100px;
  gap: 5px;
  text-align: center;
`;
const HeaderPartner = styled.div`
  text-align: center;
  font-weight: bold;
  color: blueviolet;
  margin: 5px 0;
  font-size: 20px;
`;
const HeaderTransport = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 5px;
  text-align: center;
  font-weight: bold;
`;
const Buttons = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr 70px;
`;
const Reciepts = styled.div`
  margin-top: 10px;
  height: 280px;
  overflow-y: auto;
  scrollbar-color: #102a43 #829ab1;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;
const RecieptsHeader = styled.div`
  color: blue;
  margin-bottom: 5px;
  text-align: center;
`;
const Reciept = styled.div`
  margin-bottom: 5px;
  padding: 5px 10px;
  cursor: ${props => (props.header ? '' : 'pointer')};
  background-color: ${props => (props.header ? '#ccf' : '')};
  border: 1px solid blue;
  border-radius: 5px;
  display: grid;
  gap: 5px;
  grid-template-columns: 90px 1fr;
  &:hover {
    box-shadow: ${props => (props.header ? '' : '0 0 5px blue')};
  }
`;
const RecieptHeader = styled.div`
  border-right: 1px dotted blue;
`;
const RecieptData = styled.div`
  font-style: italic;
`;
const RecieptValues = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
`;
const RecieptOrganizations = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  font-size: 12px;
`;
const RecieptOrganization = styled.div`
  border: 1px solid blue;
  word-wrap: break-word;
`;

export const UnloadDocument = ({ closeDoc, updateDoc }) => {
  const currentDocument = useSelector(
    store => store.unload.currentDocument
  );
  const currentReciept = useSelector(store => {
    return store.unload.currentReciept;
  });
  const allDefects = useSelector(store => {
    return store.common.defects;
  });
  const dispatch = useDispatch();
  const [photoes, setphotoes] = useState([]);
  const [recieptsDefects, setrecieptsDefects] = useState([]);
  const [confirmity, setconfirmity] = useState([]);

  const reciepts = currentDocument.forwardingReciepts.map(el => {
    return (
      <Reciept
        key={el.UID}
        onClick={() => dispatch(chooseReciept(el.UID))}
      >
        <RecieptHeader>
          <div>{`${el.number} (${
            photoes.filter(el1 => el1.uid === el.UID).length
          })`}</div>
          <div>{el.date}</div>
        </RecieptHeader>
        <RecieptData>
          <RecieptValues>
            <div>{el.places}</div>
            <div>{el.weight}</div>
            <div>{el.space}</div>
          </RecieptValues>
          <RecieptOrganizations>
            <RecieptOrganization>{el.sender}</RecieptOrganization>
            <RecieptOrganization>{el.receiver}</RecieptOrganization>
          </RecieptOrganizations>
        </RecieptData>
      </Reciept>
    );
  });
  reciepts.unshift(
    <Reciept key="recieptsHeader" header>
      <RecieptHeader>
        <div>номер</div>
        <div>дата</div>
      </RecieptHeader>
      <RecieptData>
        <RecieptValues>
          <div>мест</div>
          <div>вес</div>
          <div>объем</div>
        </RecieptValues>
        <RecieptOrganizations>
          <RecieptOrganization>Отправитель</RecieptOrganization>
          <RecieptOrganization>Получатель</RecieptOrganization>
        </RecieptOrganizations>
      </RecieptData>
    </Reciept>
  );

  const setDefects = defect => {
    const recieptDefects = recieptsDefects.filter(
      el => el.uid === currentReciept.UID
    );
    if (
      recieptDefects.length > 0 &&
      recieptDefects[0].defects.filter(el => el.uid === defect)
        .length > 0
    ) {
      setrecieptsDefects([
        ...recieptsDefects.filter(
          el => el.uid !== currentReciept.UID
        ),
        {
          uid: currentReciept.UID,
          defects: recieptDefects[0].defects.filter(
            el => el.uid !== defect
          ),
        },
      ]);
    } else if (recieptDefects.length > 0) {
      setrecieptsDefects([
        ...recieptsDefects.filter(
          el => el.uid !== currentReciept.UID
        ),
        {
          uid: currentReciept.UID,
          defects: [
            ...recieptDefects[0].defects,
            allDefects.filter(el => el.uid === defect)[0],
          ],
        },
      ]);
    } else {
      setrecieptsDefects([
        ...recieptsDefects.filter(
          el => el.uid !== currentReciept.UID
        ),
        {
          uid: currentReciept.UID,
          defects: [allDefects.filter(el => el.uid === defect)[0]],
        },
      ]);
    }
  };

  const setData = elConfirmity => {
    setconfirmity([
      ...confirmity.filter(el => el.uid !== currentReciept.UID),
      { uid: currentReciept.UID, value: elConfirmity },
    ]);
  };

  return (
    <Wrapper>
      {currentReciept ? (
        <Modal level={5}>
          <RecieptDocument
            closeDoc={() => dispatch(closeReciept())}
            currentReciept={currentReciept}
            photoes={photoes.filter(
              el => el.uid === currentReciept.UID
            )}
            allDefects={allDefects}
            recieptsDefects={recieptsDefects.filter(
              el => el.uid === currentReciept.UID
            )}
            confirmity={confirmity.filter(
              el => el.uid === currentReciept.UID
            )}
            setData={setData}
            setDefects={setDefects}
            takePhoto={photo =>
              setphotoes([
                ...photoes,
                {
                  photo,
                  timestamp: new Date().getTime(),
                  uid: currentReciept.UID,
                },
              ])
            }
            deletePhoto={timestamp =>
              setphotoes(
                photoes.filter(ph => {
                  return (
                    ph.uid !== currentReciept.UID ||
                    ph.timestamp !== timestamp
                  );
                })
              )
            }
          />
        </Modal>
      ) : null}
      <Buttons>
        <Button
          btnType="edit"
          clicked={() =>
            updateDoc(photoes, recieptsDefects, confirmity)
          }
        >
          {`Занести данные (${photoes.length})`}
        </Button>
        <div></div>
        <Button btnType="close" size={50} clicked={closeDoc}>
          X
        </Button>
      </Buttons>
      <HeaderNumber>
        <div>{currentDocument.number}</div>
        <div>{currentDocument.date}</div>
      </HeaderNumber>
      <HeaderPartner>
        <div>{currentDocument.partner}</div>
      </HeaderPartner>
      <HeaderTransport>
        <div>{currentDocument.transportNumber}</div>
        <div>{currentDocument.transportType}</div>
      </HeaderTransport>
      <Reciepts>
        <RecieptsHeader>Список расписок</RecieptsHeader>
        {reciepts}
      </Reciepts>
    </Wrapper>
  );
};

UnloadDocument.propTypes = {
  closeDoc: PropTypes.func.isRequired,
  updateDoc: PropTypes.func.isRequired,
};

export default UnloadDocument;
