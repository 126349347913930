import React from 'react';
import styled from 'styled-components';

import MainMenu from '../../containers/MainMenu';
import Workspace from '../../containers/Workspace';

const Wrapped = styled.div`
  width: 100%;
`;

export const Main = () => {
  return (
    <Wrapped>
      <MainMenu />
      <Workspace />
    </Wrapped>
  );
};

export default Main;
