import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import UnloadList from '../UnloadList';
import { getCommonData } from '../../store/actions/commonActions';

const Wrapper = styled.div`
  background-color: #eee;
  padding-top: 50px;
  height: 100vh;
`;

export const Workspace = () => {
  const activeTab = useSelector(store => store.menu.activeTab);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCommonData());
    return () => {};
  }, []);

  let fieldView = <h2>В разработке</h2>;
  switch (activeTab) {
    case 'load':
      fieldView = <UnloadList />;
      break;
    case 'unload':
      fieldView = <UnloadList />;
      break;

    default:
      break;
  }

  return <Wrapper>{fieldView}</Wrapper>;
};

export default Workspace;
