import { createStore, combineReducers, applyMiddleware } from 'redux';

// Middleware
import logger from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import { authReducer } from './reducers/authReducer';
import { unloadReducer } from './reducers/unloadReducer';
import { menuReducer } from './reducers/menuReducer';
import { commonReducer } from './reducers/commonReducer';

// Reducers
const reducers = combineReducers({
  auth: authReducer,
  unload: unloadReducer,
  menu: menuReducer,
  common: commonReducer,
});

const rootReducer = (state, action) => reducers(state, action);

const middleware = applyMiddleware(promise, logger, thunk);

const store = createStore(
  rootReducer,
  composeWithDevTools(middleware)
);
export default store;
